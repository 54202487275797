import React from 'react'

import { Link } from 'gatsby'
import { SiFacebook, SiInstagram } from 'react-icons/si'
import styles from './footer.module.css'
import Container from './container'

function Footer({ photoshoots }) {
  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.footerContent}>
          <div className={styles.contactGroup}>
            <ul className={styles.contactList}>
              <li className={styles.contactItem}>
                <a href="tel:+310640099910">+31 6 400 999 10</a>
              </li>
              <li className={styles.contactItem}>
                <a href="mailto:julisouza.foto@gmail.com">
                  julisouza.foto@gmail.com
                </a>
              </li>
              <li className={styles.contactItem}>
                Based in Spijkenisse, Netherlands
              </li>
            </ul>

            <ul className={styles.socialMediaList}>
              <li className={styles.socialMediaItem}>
                <a
                  aria-label="Juliana's instagram profile"
                  href="https://www.instagram.com/jusouzaphotography/"
                  target="_blank"
                >
                  <SiInstagram />
                </a>
              </li>
              <li className={styles.socialMediaItem}>
                <a
                  aria-label="Juliana's facebook profile"
                  href="https://www.facebook.com/julisouza.foto"
                  target="_blank"
                >
                  <SiFacebook />
                </a>
              </li>
            </ul>
          </div>

          <nav className={styles.navGroup}>
            <ul className={styles.navList}>
              <li className={styles.navItem}>
                <Link to="/">Home</Link>
              </li>
              <li className={styles.navItem}>
                <Link to="/about/">About</Link>
              </li>

              <li className={styles.navItem}>
                <span>Photoshoots</span>
                <ul className={styles.subNavList}>
                  {photoshoots.items.map((item, index) => (
                    <li className={styles.subNavItem} key={index}>
                      <Link to={`/${item.slug}`}>{item.name}</Link>
                    </li>
                  ))}
                </ul>
              </li>

              <li className={styles.navItem}>
                <Link to="/contact/">Contact</Link>
              </li>
            </ul>
          </nav>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
